<template>
  <div class="container">
    <a-collapse v-model:activeKey="activeKey" style="border-top-left-radius: 15px;border-top-right-radius: 15px;">
      <a-collapse-panel key="1" >
        <template #header>
          <h1>ORDERS</h1>
        </template>
        <div class="controller">
          <div class="group-item">
            <div class="item">
              <span>ตัวกรองบริษัท: </span>
              <a-select v-model:value="selectedCompanyID" style="width: 250px">
                <a-select-option :value="null">ทั้งหมด</a-select-option>
                <a-select-option v-for="company in company_list.filter(({ partner_id }) => {
                  if (selectedPartnerID) {
                    return partner_id === selectedPartnerID
                  }
                  return true
                })" :key="company.id" :value="company.id" >{{ company.name }}</a-select-option>
              </a-select>
            </div>
            
            <div class="item">
              <span>ตัวกรองพาร์ทเนอร์: </span>
              <a-select v-model:value="selectedPartnerID" @change="selectedCompanyID = null" style="width: 250px">
                <a-select-option :value="null">ทั้งหมด</a-select-option>
                <a-select-option v-for="partner in partner_list" :key="partner.id" :value="partner.id" >{{ partner.name }}</a-select-option>
              </a-select>
            </div>

            <div class="item">
              <span>รออนุมัติเท่านั้น: </span>
              <a-switch v-model:checked="show_pending" @change="get_order(), selectedRowKeys = []" />
            </div>
            <a-button class="item" type="primary" :disabled="!selectedRowKeys.length" @click="bulk_update" style="display: flex;align-items: center;width: fit-content;width: 184.61px">
              <span class="material-symbols-outlined" style="font-size: 18px">check_circle</span>
              <span>อนุมัติทั้งหมด ({{ selectedRowKeys.length }})</span>
            </a-button>
          </div>

          <div class="group-item">
            <a-range-picker class="item" v-model:value="datetime" :placeholder="['ตั้งแต่วันที่','จนถึง']" />
            <a-button class="item" type="primary" @click="export_xlsx" style="display: flex;align-items: center;width: fit-content;align-self: flex-end;">
              <span class="material-symbols-outlined" style="font-size: 18px">export_notes</span>
              <span>ส่งออกข้อมูลทั้งหมด</span>
            </a-button>
            <a-button class="item" type="primary" :disabled="!selectedRowKeys.length" @click="bulk_update" style="display: flex;align-items: center;width: fit-content;align-self: flex-end;width: 184.61px">
              <span class="material-symbols-outlined" style="font-size: 18px">heap_snapshot_large</span>
              <span>ส่งออกสัญญา ({{ selectedRowKeys.length }})</span>
            </a-button>
          </div>
        </div>
      </a-collapse-panel>
    </a-collapse><br>

    <div class="wrapper" style="height: 80%;">
      <a-table style="width: 100%" size="small"
        rowKey="id"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="[
          { title: 'วันเวลา', dataIndex: 'created_at', width: 200 },
          { title: 'บริษัท', key: 'company', ellipsis: true, width: 150 },
          { title: 'พาร์ทเนอร์', key: 'partner', ellipsis: true, width: 150 },
          { title: 'ชื่อผู้ใช้', key: 'fullname', width: 200 },
          { title: 'จำนวนเงิน', key: 'amount', width: 100, align: 'center' },
          { title: 'ค่าธรรมเนียม', key: 'fee', width: 100, align: 'center' },
          { title: 'ภาษีมูลค่าเพิ่ม', key: 'vat', width: 100, align: 'center' },
          { title: 'จำนวนเงินสุทธิ', key: 'net_transfer', width: 120, align: 'center' },
          { title: 'บัญชีธนาคาร', key: 'bank_name', width: 200 },
          { title: 'เบอร์โทรศัพท์', key: 'phone', width: 200 },
          { title: 'Status', key: 'status', width: 200, fixed: 'right', align: 'center' },
          { title: 'สัญญา', key: 'export', width: 100, fixed: 'right', align: 'center' },
        ]"
        :data-source="filtered_list(order_list)"
        :pagination="false"
        :scroll="{ x: 100 }"
      >
        <template #bodyCell="{ column: { key }, record: { id, user, status, status_id, amount, fee, vat, net_transfer } }">
          <template v-if="key === 'company'">
            <a-tooltip>
              <template #title>{{ user?.company?.name }}</template>
              {{ user?.company?.name }}
            </a-tooltip>
          </template>
          <template v-if="key === 'fullname'">
            {{ user?.fullname }}
          </template>
          <template v-if="key === 'partner'">
            <a-tooltip>
              <template #title>
                {{ user?.company?.partner?.name }}
              </template>
              <a-tag color="success">{{user?.company?.partner?.name}}</a-tag>
            </a-tooltip>
          </template>
          <template v-if="key === 'status'">
            <a-space>
              <a-badge :status="badge_color[status_id]" />
              <a-select v-model:value="status.id" style="width: 150px" @change="update_status(id, $event)">
                <a-select-option v-for="status in status_list" :key="status.id" :value="status.id" >{{ status.name }}</a-select-option>
              </a-select>
            </a-space>
          </template>
          <template v-if="key === 'bank_name'">
            <a-tooltip>
              <template #title>{{ user?.bank_name }} - {{ user?.bank_no }}</template>
              {{ user?.bank_name }} - {{ user?.bank_no }}
            </a-tooltip>
          </template> 
          <template v-if="key === 'phone'">
            <span>{{ user?.phone }}</span>
          </template>
          <template v-if="key === 'amount'">
            {{ amount?.toLocaleString() }}
          </template>
          <template v-if="key === 'fee'">
            {{ fee?.toLocaleString() }}
          </template>
          <template v-if="key === 'vat'">
            {{ vat?.toLocaleString() }}
          </template>
          <template v-if="key === 'net_transfer'">
            {{ net_transfer?.toLocaleString() }}
          </template>
          <template v-if="key === 'export'" >
            <a-tooltip placement="topLeft" title="ส่งออกสัญญา">
              <a @click="export_order(id)"><span style="font-size: 16px" class="material-symbols-outlined">share</span></a>
            </a-tooltip>
          </template>
        </template>
      </a-table>
      <a-pagination
        style="margin-top: 1rem;"
        v-model:current="page"
        v-model:pageSize="perpage"
        show-size-changer
        :total="order_total"
        @change="get_order()()"
      />
    </div>
  </div>
</template>

<script>
import { notification } from 'ant-design-vue';
import AdminService from '../api/AdminService';
import dayjs from 'dayjs';
import { exportXLSXFile } from '@/components/helper'

export default {
  name: 'Order',
  data () {
    return {
      order_list: [],
      company_list: [],
      partner_list: [],
      status_list: [],
      selectedRowKeys: [],
      selectedCompanyID: null,
      selectedPartnerID: null,
      show_pending: false,
      activeKey: ['1'],
      badge_color: {
        1: 'processing',
        2: 'success',
        3: 'error'
      },
      page: 1,
      perpage: 10,
      order_total: 0,
      datetime: [dayjs().startOf('day'), dayjs().endOf('day')],
      interval: 5000
    }
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    async export_order(id) {
      this.$message.loading({ content: 'กำลังสร้างไฟล์...', key: 'export' });
      const { base64 } = await AdminService.export_order(id)
      const buffer = Buffer.from(base64, 'base64')
      saveAs(new Blob([buffer]), `ORDER-${id.toString().padStart(6,'0')}.docx`)
      this.$message.success({ content: 'สร้างไฟล์สำเร็จ', key: 'export' });
    },
    export_xlsx() {
      this.$message.loading({ content: 'กำลังส่งออกข้อมูล...', key: 'export' });
      AdminService.get_order({ 
        time_start: this.datetime[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        time_end:  this.datetime[1].endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        page: 1, 
        perpage: 500
      }).then(async (data) => {
        const data_list = this.filtered_list(data.data).map(item => {
          return {
            'วันที่': item.created_at,
            'บริษัท': item.user?.company?.name,
            'พาร์ทเนอร์': item.user?.company?.partner?.name,
            'ผู้ใช้': item.user?.fullname,
            'จำนวนเงิน': item.amount,
            'ค่าธรรมเนียม': item.fee,
            'ภาษี': item.vat,
            'จำนวนเงินสุทธิ': item.net_transfer,
            'บัญชีธนาคาร': `${item.user?.bank_name} - ${item.user?.bank_no}`,
            'เบอร์โทร': item.user?.phone,
            'สถานะ': item.status.name,
          }
        })

        const headers = ['วันที่', 'บริษัท', 'พาร์ทเนอร์', 'ผู้ใช้', 'จำนวนเงิน','ค่าธรรมเนียม','ภาษี', 'จำนวนเงินสุทธิ',  'บัญชีธนาคาร', 'เบอร์โทร', 'สถานะ']
        await exportXLSXFile(data_list, `ORDERS-${this.datetime[0].format('DD-MM-YY')}`, headers)
        this.$message.success({ content: 'ส่งออกข้อมูลสำเร็จ', key: 'export' });
      })

    },
    async bulk_update() {
      for (var i = 0; i < this.selectedRowKeys.length; i++) {
        const id = this.selectedRowKeys[i]
        this.$message.loading({ content: `กำลังอัพเดทสถานะ ${i+1}/${this.selectedRowKeys.length}`, key: 'bulk_update' });
        await AdminService.update_model('Order', id, { data: { status_id: 2 } })
      }
      this.$message.success({ content: `อัพเดทสถานะสำเร็จ ${this.selectedRowKeys.length} รายการ`, key: 'bulk_update' });
      this.selectedRowKeys = []
      this.get_order()
    },
    update_status(id, status_id) {
      AdminService.update_model('Order', id, { data: { status_id } })
        .then(() => {
          this.$message.success('อัพเดทสถานะสำเร็จ')
          this.get_order()
        })
    },
    filtered_list(list) {
      return list
        .filter(item => {
          if (this.selectedCompanyID) {
            return item.user?.company_id === this.selectedCompanyID
          }
          return true
        })
        .filter(item => {
          if (this.selectedPartnerID) {
            return item.user?.company?.partner_id === this.selectedPartnerID
          }
          return true
        })
    },
    async get_order(show) {
      if (show) {
        const hide = this.$message.loading();
        setTimeout(hide, 1500);
      }
      const where = []
      if (this.show_pending) {
        where.push(`status_id:1`)
      }
      const res = await AdminService.get_order({ 
        time_start: this.datetime[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        time_end:  this.datetime[1].endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        page: this.page, 
        perpage: this.perpage,
        where
      });
      this.order_total = res.total
      this.order_list = res.data
    },
    async order_polling(interval) {
      if (interval) {
        setInterval(() => {
          this.get_order()
        }, interval)
      }
    },
    async get_select_options() {
      const [company, partner, status] = await Promise.all([
        AdminService.get_model('Company', { id: 'all', page: 1, perpage: 500, load: ['partner']  }),
        AdminService.get_model('Partner', { id: 'all', page: 1, perpage: 500  }),
        AdminService.get_model('Status', { id: 'all', page: 1, perpage: 500  }),
      ])
      this.company_list = company.data
      this.partner_list = partner.data
      this.status_list = status.data
    },
    notify(user) {
      const noti = new Notification('มี Order เบิกเงินเข้ามาใหม่!')
      notification['success']({
        message: 'มี Order เบิกเงินเข้ามาใหม่!',
        description: user,
      });
    }
  },
  watch: {
    order_total(newVal, oldVal) {
      const isNewOrder = dayjs(this.order_list[0].created_at).isAfter(dayjs().startOf('minute'))
      if (newVal > 0 && oldVal !== 0 && isNewOrder) {
        this.notify(this.order_list[0].user?.fullname)
      }
    },
    datetime(newVal) {
      if (newVal.length == 2) {
        this.get_order(true)
      }
    },
    selectedCompanyID(newVal) {
      if (newVal) {
        this.perpage = 500
      }
    },
    selectedPartnerID(newVal) {
      if (newVal) {
        this.perpage = 500
      }
    }
  },
  mounted() {
    this.get_order(true)
    this.get_select_options()
    this.order_polling(this.interval)
  }
}
</script>
